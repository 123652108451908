/* Add the following styles to your existing CSS or create a new stylesheet */
.table-container {
    margin: 20px;
  }
  
  .date-range-filters {
    margin-bottom: 20px;
  }
  
  /* Optional: Add a subtle gradient to the header */
  .header {
    background: linear-gradient(to right, #001529, #1890ff);
    padding: 16px;
    color: white;
    width: 100%;
    text-align: center;
  }
  
  /* Increase font size for the title */
  .header h2 {
    font-size: 24px;
  }
  
  /* Style the download button */
  .download-button-container {
    text-align: center;
    margin-top: 20px;
  }
  
  /* Optional: Add box-shadow to the modal for depth */
  .ant-modal-content {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Optional: Style the date picker */
  .date-picker-container {
    margin-bottom: 20px;
    /* display: 'flex'; */
    justify-content: center;
    margin-top: '50px';
    
  }
  
  /* Optional: Add hover effect to the table rows */
  .ant-table-row:hover {
    background-color: #f0f0f0;
  }
  /* Additional styles for the table */
.custom-table {
    margin-top: 20px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .ant-table-thead > tr > th {
    background-color: #f0f2f5;
  }
  
  .ant-table-thead th {
    font-weight: bold;
    color: #000;
  }
  
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #e8e8e8;
  }
  
  .ant-table-row:nth-child(even) {
    background-color: #fafafa;
  }
  
  /* Hover effect for better interactivity */
  .ant-table-row:hover {
    background-color: #f0f0f0;
  }
  /* Additional styles for a modern table design */
/* Additional styles for a more beautiful table */
.custom-table {
    margin-top: 20px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .ant-table-thead > tr > th {
    background-color: #1890ff;
    color: #fff;
    font-weight: bold;
    text-align: left;
    border-bottom: 2px solid #096dd9; /* Add a bottom border for a separated header */
  }
  
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #e8e8e8;
    color: #555; /* Adjust text color for better readability */
  }
  
  .ant-table-row {
    transition: background-color 0.3s;
  }
  
  /* Hover effect for better interactivity */
  .ant-table-row:hover {
    background-color: #e6f7ff;
  }
  
  /* Alternate row background color for better distinction */
  .ant-table-row:nth-child(even) {
    background-color: #f6f8fa;
  }
  
  /* Style the pagination component */
  .ant-pagination {
    margin-top: 10px;
  }
  
  /* Responsive design: make the table scroll horizontally on smaller screens */
  @media screen and (max-width: 767px) {
    .custom-table {
      overflow-x: auto;
    }
  }
  /* Additional styles for icons and buttons */
.custom-table .anticon {
    font-size: 16px;
    margin-right: 8px;
    color: #1890ff; /* Adjust icon color */
  }
  
  .custom-table .ant-btn {
    background-color: #98b2ca;
    border-color: #1890ff;
    color: #fff;
  }
  
  .custom-table .ant-btn:hover {
    background-color: #096dd9;
    border-color: #096dd9;
  }
  .custom-table .ant-btn {
    transition: background-color 0.3s;
  }
  
  .custom-table .ant-btn:hover {
    background-color: #ffffff; /* Adjust hover background color */
    border-color: #40a9ff; /* Adjust hover border color */
    color: #fff;
  }
  .ant-modal-content {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .ant-modal-footer {
    border-top: none;
    padding: 10px 20px;
  }
  /* Additional styles for decorative elements */
.custom-table {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .date-picker-container {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 10px;
  }
  /* Additional styles for a card-like appearance in the modal */
.modal-card {
    margin-top:'20px';
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .modal-card-header {
    margin-top: 20px;
    background-color: #1890ff;
    color: #fff;
    padding: 16px;
  }
  
  .modal-card-content {
    padding: 20px;
  }
  
  .modal-card-content p {
    margin-bottom: 10px;
  }
/* Additional styles for animated transitions */
.modal-card {
    transition: transform 0.3s ease-in-out;
  }
  
  /* Apply a scale effect on hover */
  .modal-card:hover {
    transform: scale(1.02);
  }
/* Additional styles for custom loading indicator */
.custom-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
  }
  /* Add these styles to your CSS or SCSS file */
.row-counter-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .counter {
    text-align: center;
    padding: 20px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 100%; /* Ensure the counter takes full height */
  }
  
  .counter-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .counter-value {
    font-size: 24px;
    font-weight: 700;
    color: #1890ff; /* Adjust the color to your preference */
  }
  .center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  